body{
    overflow:  hidden !important;
    
}
.nunito-family{
    font-family: "Nunito" !important;
}
body {
    margin: 0;
    font-family: "Nunito", sans-serif !important;
    background: #F5F5F5;
  }
.formcontainer{
    font-family: "Nunito", sans-serif !important;
}
  .card{
    background-color: #ffffff !important;
  }
.option{
    /* float:right !important ;
    padding-left: 450px !important;
    justify-content: center;
    align-items: center;
    font-size:10px;
    border: none;
    display: grid !important;
    right: 60px !important;
    place-content: center !important; */
    float: right !important;
    align-items: center;
    padding-top: 250px;
    font-size: 10px;
    border: none;
    display: grid;
    right: -50px !important;
    position: relative;
    justify-content: center;
    place-items: flex-end;
}

.card-login {
    padding: 4.5rem 20rem 12.5rem 20rem;
    place-content: center !important;
    place-items: center !important ;
    width: 100% !important;

}

.CardHeader{
    display: flex !important;
    place-items: center !important;
    justify-content: center;
}
.card-body{
    display: grid !important;
    margin-top: 16% !important;
    /* display: flex;
    place-content: center !important;
    /* align-items: center; */
    /* justify-content: center;  */
    padding: 0% 27% 0% 27%;
    width: 100% !important;
    background-color: #ffffff !important;
}
.card-body-signup{
    /* display: grid !important; */
    margin-top: 5% !important;
    /* display: flex; */
    place-content: center !important;
    align-items: center;
    justify-content: center;
    padding: 0% 26% 0% 24%;
    width: 100% !important;
    background-color: #ffffff !important;
}

.active{
    background-color: rgb(255, 255, 255) !important;
    color:black !important;
    
}
.inactive{
    background-color: green;
    color:aliceblue;
}
.des-view{
    padding: 0px;
    margin: auto;
    height: 100vh;
}

.col-md-8{
    margin: 0px;
    border: none;
    z-index: 2;
}
.footer{
    height: 35px !important;
    border-top: 1px ;
    /* padding: 80px 80px 0px; */
    /* padding-top: 100px;
    padding-left: 180px;
    padding-right: 180px; */
    display: flex !important;
    /* margin-top: 15% !important; */
    color: #8c8c8c;
    font-size:"18px";
    background: linear-gradient(180deg, #e1e1e1, #ffffff);
    padding-bottom: 2% !important;
    justify-content: space-evenly !important;

}

.username,.userpassword{
    
    border:none;
    outline: none;
    gap: 10px !important;
    border-bottom: 2px solid gray;
    
      
}
.input-fields{
    display: grid;
    place-content: center;
    align-items: center !important;
    /* font-size: 30px !important; */
    padding-bottom: 0px;
    color:"gray";
    margin-top: 20%;
    width: 100% !important;
}

.log-row-button{
    height: 50px;
}
h4{
    margin-top: 8px;
}
.col-md-6{

    align-items: center;
    justify-content: center;
}
.col-md-6 button{
    border-radius: 23px;
    width: 100px;
    font-size: 17px;
}
.googleicon{
    height: 30px !important;
    width: 30px !important;
}
.des-view-col{
    background-image: url('../../assets/signin/zeralog1.png') !important; 
    background-size: cover;
    background-position: center;
    height: 100vh !important;
  
}
.formbtn,.signup-btn {
    background: linear-gradient(90deg,#0E8C43, #005323);
    border:none;
    color: aliceblue;
    height: 40px;
    border-radius: 23px !important;
    width:30% !important;
    /* font-size: 16px !important; */
}
.signup-btn{
    width:26% !important;
    border-radius: 30px !important;
}
 
@media screen and (min-width:576px) and (max-width:766px){
    .des-view{
        display: none !important;
    }
    /* .formcontainer{

        width: 100vh !important;
        height: 100vh !important;
        background-image: url('../../assets/5863501.jpg') !important;
        background-size: cover;
        background-position: center;
    } */
    .mob-view {

        
        /* background-image: url('../../assets/5863501.jpg') !important; */
        /* background-size: cover;
        background-position: center; */
        /* display: grid !important;
        place-content:center !important;
        
        padding: 10px 10px 10px 10px; */
        height: 100vh !important; 
        overflow-y: scroll;
        overflow-x: hidden !important;
    }
    .CardHeader-mob{
        display: flex !important;
        place-items: center !important;
        justify-content: center;
        margin-top: 1%;
    }
    .rows{
        display: none;

    }
    .formbtn{
        width: 45% !important;
    }
    .row-mob-view{
        display: flex !important;

    }
    .input-fields{
    display: grid;
    place-content: center;
    align-items: center !important;
    /* font-size: 30px !important; */
    padding-bottom: 0px;
    color:"gray";
    margin-top: 15%;
    width: 100% !important;
    }
    .footer-mob{
        
        background: white !important;
        /* place-content: "center" !important; */
        /* justify-content: start !important; */
        /* margin-top: 20% !important; */
        margin-top: 100% !important;
        border: none;
        align-items: center;
        justify-content: center;
        color: rgb(98, 97, 97);
    }
    .card{
        border-top-left-radius:0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .card-body-mob-view{
        margin: auto;
        padding: auto !important;
        display:grid;
        align-items: center;
        justify-content: center;
        text-align: -webkit-center !important;
        background-color: #ffffff !important;
    }
    .form_input{
        margin-top: 5% !important;
    }
    .signupdetails-mob{
        margin-left: 10% !important;
    }
}
@media screen and (min-width:912px) and (max-width:1368px){
    .card-body-signup{
        padding: 10% !important;
        background-color: #ffffff !important;
    }
    .mob-view{
        display: none !important;
    }
}
@media  screen and (min-width:912px) and (max-width:1368px){
    .mob-view{
        display: none !important;
    }
    .CardHeader-mob{
        display:none !important;
    }
}

@media  screen and (min-width:768px) and (max-width:1200px){
    .mob-view{
        display: none !important;
    }
    .signupdetails-mob{
        margin-left: 10% !important;
    }
    .col-mob{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    }
    .formbtn{
        width: 25% !important;
    }
    .signup-btn{
        width: 30% !important;
    }

    .card-login{
        padding: 20% !important;
    }
    .card-body-mob-view{
        margin: none;
        padding: none !important;
        display:grid;
        align-items: center;
        justify-content: center;
        text-align: -webkit-center !important;
        background-color: #ffffff !important;
    }
    .mob-view{
        display: none !important;
    }
}
@media (min-width:320px) and (max-width:767px){
    .des-view{
        display: none !important;
    }
    /* .formcontainer{

        width: 100vh !important;
        height: 100vh !important;
        background-image: url('../../assets/5863501.jpg') !important;
        background-size: cover;
        background-position: center;
    } */
    .mob-view {

        
        /* background-image: url('../../assets/5863501.jpg') !important; */
        /* background-size: cover;
        background-position: center; */
        /* display: grid !important;
        place-content:center !important;
        
        padding: 10px 10px 10px 10px; */
        height: 100vh !important; 
        overflow-y: scroll;
        overflow-x: hidden !important;
    }
    .CardHeader-mob{
        display: flex !important;
        place-items: center !important;
        justify-content: center;
        margin-top: 1%;
    }
    .rows{
        display: none;

    }
    .formbtn{
        width: 45% !important;
    }
    .row-mob-view{
        display: flex !important;

    }
    .input-fields{
    display: grid;
    place-content: center;
    align-items: center !important;
    /* font-size: 30px !important; */
    padding-bottom: 0px;
    color:"gray";
    margin-top: 15%;
    width: 100% !important;
    }
    .footer-mob{
        
        background: white !important;
        /* place-content: "center" !important; */
        /* justify-content: start !important; */
        /* margin-top: 20% !important; */
        margin-top: 0px !important;
        border: none;
        align-items: center;
        justify-content: center;
        color: rgb(98, 97, 97);
    }
    .card{
        border-top-left-radius:0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .card-body-mob-view{
        margin: auto;
        padding: auto !important;
        display:grid;
        align-items: center;
        justify-content: center;
        text-align: -webkit-center !important;
        background-color: #ffffff !important;
    }
    .form_input{
        margin-top: 5% !important;
    }

}
