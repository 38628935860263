body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
}

code {
  font-family: 'Nunito',
    monospace;
}
.wrapper {
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  background: rgba(247, 255, 250, 1);
}
