body {
  font-family: "Nunito";
  background-color: rgba(247, 255, 250, 1);
}

.dashboard {
  background-color: rgba(247, 255, 250, 1);
  font-family: "Nunito", sans-serif;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 70px 0px;
}

.bar-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.chart-info {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.05px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-info div {
  width: 22%;
}

.overview-card-body {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.card-title {
  font-family: "Nunito", sans-serif;
  font-size: 14.06px;
  font-weight: 500;
  line-height: 19.18px;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(32, 34, 36, 0.7);;
}

.card-info {
  font-size: 24.6px;
  font-weight: 700;
  line-height: 33.56px;
  letter-spacing: 0.8px;
  text-align: left;
  color: rgba(32, 34, 36, 1);
  margin-bottom: 0px;
  margin-top: 4%;
}

.card-details {
  height: 50px;
}

.card-section {
  width: 23%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 10px 12px 14px;
  height: 145px;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}

.card-section h2 {
  margin-top: 0;
}
.card-section span {
  font-family: "Nunito", sans-serif;
  /* font-size: 16px; */
}
.leads-icon {
  position: absolute;
  top: 30%;
  right: 15px;
  transform: translateY(-50%);
  width: 60px;
  height: auto;
}
.percentage-container {
  display: flex;
  align-items: center;
  color: rgba(32, 34, 36, 0.7);
  font-size: 14px;
  font-weight: 500;
}

.percentage {
  color: rgba(0, 182, 155, 1);
  margin-left: 1.5%;
  margin-right: 5px;
}

.container {
  display: flex;
  flex-direction: row;
  padding: 0px 70px;
}
.negative {
  color: red;
}
.card-chart {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  position: relative;
  margin-right: 20px;
  margin-bottom: 32px;
  box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
  height: 60%;
}
.card-chart p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.7px;
  color: rgba(172, 172, 172, 1);
  text-align: left;
  margin-bottom: 0px;
}
.select-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card-chart2 {
  flex: 1; /* Change to a flexible width */
  padding: 10px;
  border-radius: 5px;
  height: 57%;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.container-table {
  box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0px 70px 70px;
  position: relative;
}

.table {
  --bs-table-border-color: #fff;
  --bs-table-bg: rgba(245, 254, 249, 1);
}

.table th,
td {
  text-align: center;
}

.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
}

thead tr {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.82px;
  letter-spacing: 0.4px;
  color: rgba(181, 183, 192, 1);
  background-color: #fff;
}

.table td {
  font-size: 16px;
  font-weight: 500;
  line-height: 24.55px;
  letter-spacing: 0.4px;
  color: rgba(96, 96, 96, 1);
}

.table-header {
  padding: 20px 40px;
}

.table-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: #fff;
}

.container-table p {
  font-size: 22px;
  font-weight: 600;
  line-height: 30.01px;
  letter-spacing: 0.4px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  width: 22%;
  margin-bottom: 0px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  margin: 10px;
  height: 40px;
  width: 15%;
}

select {
  border: none;
  background-color: rgba(249, 251, 255, 1);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  letter-spacing: 0.4px;
  color: rgba(126, 126, 126, 1);
}

.search-field1 {
  width: 25%;
  margin-right: 3%;
}

.table-search {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
}

.search-input {
  padding: 10px 30px 10px 40px;
  background-image: url("../../assets/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  background-color: rgba(249, 251, 255, 1);
  box-shadow: 0px 10.53px 63.16px 0px rgba(178, 255, 211, 0.13);
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  letter-spacing: 0.4px;
  color: rgba(181, 183, 192, 1);
}

.bold-text {
  font-weight: bold;
}

.product-info {
  display: flex;
  align-items: flex-start;
}

.product-details {
  margin-left: 10px;
  text-align: left;
}

.product-image {
  width: 50px;
  height: 50px;
}
.product-details span {
  display: block;
  text-align: left;
}
.product-name-column {
  width: 500px;
}

.error-message-container {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 10%;
}

/* For screen widths between 320px and 767px */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .card-section {
    width: calc(100% - 20px);
    margin-bottom: 15px;
  }

  .container {
    flex-direction: column; /* Stack cards vertically */
    padding: 10px; /* Adjust padding for spacing */
  }

  .card-chart {
    flex: 100%; /* Take full width */
    padding-right: 0; /* Remove right padding */
    margin-right: 0px !important;
    margin-top: 50px;
  }

  .card-chart2 {
    flex: 100%; /* Take full width */
    padding: 0; /* Remove padding */
  }

  .leads-icon {
    width: 40px; /* Adjust icon size */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-section {
    width: calc(33.33% - 20px); 
    margin-bottom: 15px;
  }

  .container {
    flex-direction: row; /* Revert to horizontal layout */
    padding: 20px; /* Reset padding */
  }

  .card-chart {
    flex: 60%; /* Adjust chart width */
  }

  .card-chart2 {
    flex: 40%; /* Adjust chart width */
  }

  .leads-icon {
    width: 40px; /* Adjust icon size */
  }
}

@media screen and (max-width: 650px) {
  .card-section {
    width: 100%; 
    margin-bottom: 15px;
  }

  .card-container {
    padding: 14px;
  }

  .-tabcontainer-dashboardtablele {
    margin: 14px;
  }

  .table-header {
    flex-direction: column;
    padding: 20px;
  }

  .container-table p {
    font-size: 20px;
    font-weight: 500;
    line-height: 30.01px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    width: 100%;
    margin-bottom: 0px;
  }

  .table-search {
    width: 100%;
    justify-content: space-between;
  }

  .search-field1 {
    width: 60%;
  }

  .dropdown-container {
    margin: 10px;
    height: 40px;
    width: 40%;
  }

  th {
    text-wrap: nowrap;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1440px;
    margin: 0px !important;
  }
}
