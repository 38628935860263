.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 70px;
  background-color: rgba(245, 245, 245, 1);
  color: black;
}

.logo img {
  height: 40px; /* Adjust height as needed */
}

.left-side {
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32.74px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.title h1 {
  margin: 0;
}

.search-field {
  display: flex;
  align-items: center;
  margin-left: 550px;
}

.search-input {
  padding: 10px 30px 10px 40px;
  background-image: url("../../assets/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  background-color:rgba(255, 255, 255, 1);
  box-shadow: 0px 10.53px 63.16px 0px rgba(178, 255, 211, 0.13);
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 13px;
}

.add-button {
  margin-right: 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px;
  width: 60px;
  height: 35px;
  margin-top: 3px;
}

.add-button img {
  width: 100% !important;
  height: 100% !important;
}

.additional-content {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  height: 100px;
  margin-top: 162px;
  width: calc(24% - 24px);
  z-index: 999;
}

.additional-content p {
  color: #000000;
  font-size: 14px;
  margin-bottom: 5px;
}
.card-title-search {
  color: black;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px; /* Optional: Set maximum width for the modal */
  width: 90%;
  max-height: 90%; /* Set maximum height for the modal */
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 20px;
  height: 20px;
}
.submit-button {
  bottom: 0px;
  right: 20px;
  background: linear-gradient(
    to bottom,
    rgba(136, 61, 207, 1),
    rgba(69, 31, 105, 1)
  );
  border: none;
  color: white;
  border-radius: 5px;
  height: 30px;
}
.draft-button {
  margin-left: 69%;
  bottom: 0px;
  right: 20px;
  border: none;
  border-radius: 5px;
  height: 30px;
  color: purple;
  background-color: rgba(244, 236, 251, 1);
}
.button-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjust as needed */
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.notmem {
  margin-left: 90%;
}

@media (max-width: 768px) {
  .button-row {
    flex-direction: column;
  }

  .submit-button,
  .draft-button {
    width: 100%;
    margin-bottom: 10px;
  }
  .search-field {
    display: flex;
    align-items: center;
    margin-left: 0;
  }
  .header{
    padding: 2px;
  }
}
