body {
  font-family: "Nunito" !important;
  background-color: rgba(247, 255, 250, 1) !important;
  overflow: hidden !important;
}
.lead-summery-card {
  display: flex;
  justify-content: space-between;
  /* padding: 0% 6% 0% 6%; */
  font-size: 20px;
  align-items: center !important;
}
.listitem{
  display: grid !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px !important;
}
.dropdown-item{
  display: flex !important;
  justify-content: start !important;
  padding: 8px  0px 0px 0px !important;
  width: 100% !important;
}
.dropdown-menu{
  padding: none !important;
}
.pending-status {
  display: grid;
  justify-content: center;
}
.pending-status-response {
  /* padding-top: 10%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: -webkit-center;
}
.leadsource-card-body {
  height: 395px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0px !important;
}
.lead-summerybody {
  margin: 0px !important;
}
.btn:hover,.btn:focus{
  background-color: #ffffff  !important;
  color:  #000000 !important;
  border: none !important
  ;
}
.dropdown-toggle-lead {
  background: #ffffff;
  color: black;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 180.22px;
  height: 38px;
  top: 319px !important;
  left: 1192.78px !important;
  gap: 0px;
  border: 0.02px 0px 0px 0px !important;
  opacity: 10px;
  border-width: 0px, 0px, 0px, 0px;
  border-color: #e7e7e7;
}

.overview-card-head-text {
  width: 104px;
  height: 19px;
  top: 61px;
  left: 40px;
  gap: 0px;
}
.overviewcardhead {
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #acacac;
  margin: none !important;
}
.summery-para {
  width: 224px;
  height: 17px;
  top: 330px;
  left: 373px;
  gap: 0px;
  opacity: 66%;
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: left;
}
.dropdowntext {
  width: 21.36px;
  height: 16px;
  top: 329px;
  left: 1212.8px;
  gap: 0px;
  font-family: "Nunito";
  font-size: 12px;
  font-weight: 600;
  line-height: 16.37px;
  letter-spacing: -0.01em;
  text-align: left;
}
.overview-card-header,
.leadsource-card-header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.table-admindashboard {
  color: black !important;
  border-width: 0.1px !important;
  border-style: solid !important;
  border-color: #c7c6c6 !important;
  border-radius: 5px !important;
  font-weight: 400;
  font-family: 'Nunito' !important;
}
.heading {
  padding-top: 3% 0% 3% 0% !important;
}
body {
  background-color: rgba(247, 255, 250, 1);
}
.admindasnboard {
  padding: 30px 20px;
}

.card-container-admin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1% 0% 3% 0% !important;
}
.crd-group {
  padding-bottom: 5% !important;
}
.card-rows {
  display: flex !important;
  justify-content: space-between !important;
}
.top10coldesign {
  /* padding: 15px !important; */
}
.dropdown-menu{
  height: fit-content !important;
  backdrop-filter:blur(5px) !important;
  background-color: none !important;
}
.table-admindashboard{
  font-family: 'Nunito' !important;
    text-align: center !important;
}

.header-top10-card,
.header-top10-closed,
.header-pending-deals {
font-size: 14.29px;
font-weight: 560;
line-height: 19.49px;
text-align: left;
margin-bottom: 15px !important;

}
.container-admin{
  margin: 0px  !important;
}
.card-top10 {
  height: 3% !important;
  width: 100% !important;
  display: grid !important;
  padding: 3% 5% !important;
  weight: 400 !important;
  border: 0.8px solid rgba(2, 90, 39, 1) !important;
  border-radius: 15px;
  align-items: center !important;
  background: rgba(247, 255, 250, 1) !important;
}
.overview-card,
.leadsource-card,
.lead-summery {
  width: 100% !important;
  margin: 0.2%;
  padding: 2% 4% 0% 4%;
  /* box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05); */
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

th {
  color: rgb(0, 0, 0);
  font-weight: 400 !important;
  font-family: 'Nunito' !important;
}
/* th:first-child {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
td:last-child {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
} */
tbody tr th {
  color: black !important;
  border-color: #ccc;
  font-size: 14.29px;
  font-family: 'Nunito' !important;
}
td {
  color: black !important;
  font-weight: 400;
  border: 1px solid #ccc;
  font-family: 'Nunito' !important;
}
table {
  border-collapse: collapse;
  border-radius: 2px !important;
  overflow: hidden;
  border: 1px solid #ccc;
  outline: 1px solid #ccc;
  font-family: 'Nunito' !important;
}

table th,
table td {
  border: 1px solid;
  padding: 12px;
  font-family: 'Nunito' !important;
}
.card-group {
  width: 100%;
  height: 83px;
  top: 61.04px;
  left: 11px;
  gap: 0px;
  /* border-radius: 13.39px 15px 15px 13.39px; */
  /* opacity: 0px; */
  margin-bottom: 6%;
}
.top10cardsgroup {
  width: 100% !important;
  height: 83px !important;
  gap: 0px;
  border-radius: 13.39px 15px 15px 13.39px !important;
  border: 0.8px 0px 0px 0px !important;
  /* opacity: 0px !important; */
  border: 0.8px solid #025a27 !important ;
  background: #f7fffa !important;
  /* border: 0.05px solid #C7C6C6; */
  padding: 0% !important;
  /* z-index: 0; */
}
.cardgroup-text1 {
  padding-right: 3% !important;
}
.cardgroup-text {
  font-family: "Nunito";
  font-size: 14.29px;
  font-weight: 400;
  line-height: 19.49px;
  text-align: left;
}
.top10cardsgroup-header{
  border-radius: 13.39px 15px 0px 0px !important;

}
.top10cardsgroup-header,
.top10cardsgroup-body {
  display: flex !important;
  justify-content: space-between !important;
  background: #f7fffa !important;
  z-index: 1;
  
}
.dropdown-toggle::after{
  display: none !important;
}
.top10cardsgroup-body {
  margin: 0px !important;
  border-radius: 13.39px 15px 15px 13.39px !important;
  padding-bottom:0px !important;
  background: #f7fffa !important;
}
.top10-cards,
.top10-closed,
.pending-deals {
  height: 93% !important;
  width: 100% !important;
  padding: 24px 15px !important;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 60px 0px #e2ecf980;
  border: 0.3px solid #C7C6C6 !important;
}

.top10coldesign {
  height: 600px !important;
}
.top10 {
  padding-bottom: 5px !important;
}
.row-top10 {
  display: grid;
}
.col-md-12-deals {
  display: flex;
  justify-content: space-between;
}
.leadsource-summery {
  display: flex;
  justify-content: space-between;
}
.dropdown-overview {
  background: #ffffff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 118px;
  height: 38px;
  top: 319px;
  left: 1192.78px;
  gap: 0px;
  border: 0.02px 0px 0px 0px !important;
  opacity: 10px;
  border-width: 0px, 0px, 0px, 0px;
  border-color: #e7e7e7;
  color:#7E7E7E !important;
  font-family:"Nunito";
font-size: 12px;
font-weight: 400;
line-height: 16.37px;
letter-spacing: -0.01em;
text-align: left;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: transparent !important; /* Remove the background color change */
  color: inherit !important; /* Keep the text color as it is */
}
.card-title {
  font-size: 14.06px;
  font-weight: 600;
  line-height: 19.18px;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(32, 34, 36, 1);
  opacity: 70%;
}

.card-info {
  font-size: 24.6px;
  font-weight: 700;
  line-height: 33.56px;
  letter-spacing: 0.8px;
  text-align: left;
  color: rgba(32, 34, 36, 1);
  margin-bottom: 0px;
  margin-top: 4%;
}

.card-details {
  height: 50px;
}

.card {
  width: 23%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 10px 12px 14px;
  height: 135px;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}
.leadandsummerymanage {
  padding-top: 5% !important;
}
.card-leadsource-card {
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 23px 12px 14px;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}

.card h2 {
  margin-top: 0;
}
.card span {
  font-size: 13px;
}
.leads-icon {
  position: absolute;
  top: 30%;
  right: 15px;
  transform: translateY(-50%);
  width: 60px;
  height: auto;
}
.percentage-container {
  display: flex;
  align-items: center;
}

.percentage {
  color: rgba(0, 182, 155, 1);
  margin-right: 5px;
}

.container {
  display: flex;
  flex-direction: row;
  padding: 40px;
  margin-top: -70px;
}
.negative {
  color: red;
}

.select-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.container-table {
  padding: 20px;
  box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0px 70px;
  position: relative;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  margin: 10px;
}
.bold-text {
  font-weight: bold;
}

.product-info {
  display: flex;
  align-items: flex-start;
}

.product-details {
  margin-left: 10px;
  text-align: left;
}

.product-image {
  width: 50px;
  height: 50px;
}
.product-details span {
  display: block;
  text-align: left;
}
.product-name-column {
  width: 500px;
}

.error-message-container {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 10%;
}
.overview-card-bodyadmin {
  height:470px;
  display: flex;
  justify-content: center;
  margin: 0px !important;
}
.center_text {
  position: absolute;
  /* top: 90%; */
  right: 80%;
  /* left: 25\% */
}
.bottom_text1 {
  position: absolute;
  bottom: 40%;
  left: 46%;
}
.bottom_text2 {
  position: absolute;
  bottom: 2%;
  left:15% !important;
}
.bottom_text3 {
  position: absolute;
  bottom: 2%;
  right:15% !important;
}
.center_text p {
  display: grid;
}
.container {
  overflow: hidden !important;
}

/* For screen widths between 320px and 767px */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .admindasnboard{
    padding: 2px 2px !important;
  }
  
  .dropdown{
    margin-top: 20px  !important;
  }
  body{
    overflow-x: hidden !important;
  }
  .card-container-admin{
    justify-content: center !important;
  }
  .container-admin{
    margin: 2% !important;
  }
  .pending-status-response {
    display: block !important;
    align-items: center !important;
  }
  .pending-status-response{
    display: flex !important;
    justify-content: space-between !important;
  }
  .overview-dropdown {
    width: 100% !important;
  }
  .lead-summery-card,
  .overview-card-header,
  .leadsource-card-header {
    display: block !important;
    margin-bottom: 5px ;
  }
  .lead-summery-card{
    padding-left: 10px;
  }
  .dropdow{
    margin-top: 10px !important;
  }
  .center_text {
    position: absolute;
    top: 56% !important;
    right: 70%;
    left: 26% !important ;
  }
  .bottom_text1 {
    position: absolute;
    bottom: 43% !important;
    left: 43% !important;
  }
  .bottom_text2 {
    position: absolute;
    bottom: 9% !important;
    left: 20% ;
  }
  .bottom_text3 {
    position: absolute;
    bottom: 9% !important;
    right: 20% ;
  }
  .pending-status-notreachable{
    width: 16px;
  }
  .leadsource-card-body {
    height: 300px !important;
    margin: 0px;
  }
  .overview-card-bodyadmin {
    height: 150px;
    margin: 0px !important;
  }
  .top10coldesign {
    height: 400px !important;
    padding-bottom: 15px !important;
  }
  .top10-closed {
    overflow-y: scroll !important;
  }
  .top10-cards {
    overflow-y: scroll !important;
  }
  .userreporttable {
    overflow-x: scroll !important;
  }
  .card {
    width: calc(100% - 20px);
    margin-bottom: 15px;
  }

  .container {
    flex-direction: column; /* Stack cards vertically */
    padding: 10px; /* Adjust padding for spacing */
  }

 

 

  .leads-icon {
    width: 40px; /* Adjust icon size */
  }
}

/* For screen widths between 768px and 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .center_text {
    position: absolute;
    top: 52%;
    right: 70%;
    left: 33%;
  }
  .bottom_text1 {
    position: absolute;
    bottom: 48%;
    left: 46%;
  }
  .bottom_text2 {
    position: absolute;
    bottom: 6%;
    left: 22%  !important;
  }
  .bottom_text3 {
    position: absolute;
    bottom: 6%;
    right: 22% !important;
  }
  .leadsource {
    width: 100% !important ;
  }
  .col-md-5 {
    width: 100% !important;
  }
  .overview-card-bodyadmin {
    height: 310px !important;
  }
  .card {
    width: calc(33.33% - 20px); /* Three cards per row with some spacing */
    margin-bottom: 15px;
  }

  .container {
    flex-direction: row; /* Revert to horizontal layout */
    padding: 20px; /* Reset padding */
  }

  .leads-icon {
    width: 40px; /* Adjust icon size */
  }
}

.center_text {
  position: absolute;
  top: 52%;
  right: 70%;
  left: 34%;
}
.bottom_text1 {
  position: absolute;
  bottom: 48%;
  left: 46%;
}
.bottom_text2 {
  position: absolute;
  bottom: 6%;
  left: 22% !important;
}
.bottom_text3 {
  position: absolute;
  bottom: 6%;
  right: 20% !important;
}
.center_text p {
  display: grid;
}
.chart-container {
  width: 300px;
  margin: auto;
  text-align: center;
}

.svg-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.circular-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle {
  fill: none;
  stroke-width: 3.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.cold-call {
  stroke: #36a2eb;
}

.social-media {
  stroke: #4bc0c0;
}

.referral {
  stroke: #ffcd56;
}

.advertisement {
  stroke: #ff6384;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

.chart-labels {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.cold-call {
  color: #36a2eb;
}

.social-media {
  color: #4bc0c0;
}

.referral {
  color: #ffcd56;
}

.advertisement {
  color: #ff6384;
}
hr {
  /* border: 0.02px solid #C7C6C6 !important */
  border: 0.1px solid #C7C6C6 !important;
}

