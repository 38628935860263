.progress-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 42%;
  margin: auto;
}

.circular-chart {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 5.8;
}

text {
  color: #fff;
}

.circle {
  fill: none;
  stroke-width: 5.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s;
}

@media (min-width:900px and max-width: 1200px) { 
  .progress-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 42%;
    margin: auto;
  }
}
