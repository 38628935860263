.leads {
  background-color: rgba(247, 255, 250, 1);
  padding: 3%;
  height: 100%;
}

.lead-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.lead-table {
  z-index: 0 !important;
}

.create {
  margin-right: 3% !important;
  z-index: 1 !important;
}
.lead-table {
  z-index: 0 !important;
  position: relative;
}
.rs-btn {
  background: linear-gradient(180deg, #0c8740 0%, #015625 100%);
  color: rgba(255, 255, 255, 1);
}
.rs-table-header-row-wrapper {
  z-index: 0 !important;
}

.rs-dropdown-menu {
  height: auto;
  width: auto;
}

.rs-btn:focus,
.rs-btn:hover {
  color: rgba(255, 255, 255, 1);
}

.rs-table-cell-content {
  text-align: center;
}
.combobox,
.leadtable {
  z-index: 0 !important;
}
.body {
  background-color: rgba(247, 255, 250, 1);
}
.control-file-csv {
  align-items: center !important;
}
.tabletextfields {
  display: flex;
  align-items: center;
}
.leadtablegrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  padding: 1% 6% 1% 5%;
}
.dropdown-lead {
  width: 135px !important;
  height: 38px !important;
  /* top: 215px;
  left: 1314px; */
  gap: 50px;
  border-radius: 10px !important;
  /* opacity: 0px; */
  background-color: #f9fbff !important;
  align-items: center;
}
.dropdownleadtext {
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.37px;
  letter-spacing: -0.01em;
  text-align: left;
}
.leadsearch {
  border: 0px !important;
  outline: none;
}
.leadsearch:focus {
  border: 0px !important;
  outline: none;
}

.lead-dropdown .dropdown-toggle .dropdownleadtext {
  margin-right: 15px !important;
}
.leadtablefont{
  font-family: Nunito;
font-size: 22px;
font-weight: 600;
line-height: 30.01px;
letter-spacing: -0.01em;
text-align: left;
color: black;
}
.leadsearchrow{
  align-items: center;
}
