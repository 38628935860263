.createform {
  padding: 5%;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-row div {
  width: 50%; 
}

.input-row label {
  margin-bottom: 5px;
}

.input-row input {
  width: 90%;
  padding: 8px;
  border: none;
  background: rgba(247, 255, 250, 1);
  border-radius: 5px;
  height: 50%;
}

.input-row input,
.input-row select,
.input-row textarea {
  width: 90%;
  background: rgba(247, 255, 250, 1);
  padding: 8px;
  border: none;
}

form {
  padding-top: 2%;
}

.field-card {
  height: auto;
  width: 100%;
}

.form-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

.form-tabs div {
  background: green;
  color: #fff;
  width: 12%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.visible {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
