.addlead {
  padding: 4%;
  font-family: "Nunito", sans-serif;
}

.lead-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5%;
}

.lead-title span {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.body{
  background-color:  rgba(247, 255, 250, 1);
}

label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1%;
  color: rgba(3, 2, 41, 1);
}

.addleadinput,
textarea,
select {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
  height: 52px;
  width: 92%;
  padding: 0px 4%;
}

textarea {
  height: 100px;
  padding: 2% 4%;
}

.lead-form {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.lead-form div {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
}

.newlead-btn {
  width: 220px;
  height: 53.04px;
  top: 858px;
  left: 773px;
  padding: 13.02px 18.23px 13.02px 18.23px;
  gap: 5.21px;
  border-radius: 10.42px 10.42px 10.42px 10.42px;
  opacity: 0px;
  background: linear-gradient(180deg, #0e8c43 0%, #005323 100%);
  color: #ffff;
  font-size: 18.23px;
  font-weight: 600;
  line-height: 26.04px;
  letter-spacing: 0.005em;
  text-align: center;
}
.btnleadnew{
  padding-top: 5% !important;
  justify-content: center !important;
  display: flex !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .addleadinput,
  select,
  textarea {
    width: 100% !important;
  }
  .lead-form {
    width: 100% !important;
  }
  .leadformfields{
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lead-form {
    display: grid !important;
  }
  .lead-form div {
    width: 100% !important;
  }
  .addleadinput,
  select,
  textarea {
    width: 100% !important;
  }
}
